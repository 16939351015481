const MiddleEastTNCounties = [
    {
        name: "Bledsoe",
        government: "https://bledsoetn.com/",
        demographics: "https://data.census.gov/profile/Bledsoe_County,_Tennessee?g=050XX00US47007",
        cities: ["Pikeville"]
    },
    {
        name: "Bradley",
        government: "https://bradleycountytn.gov/",
        demographics: "https://data.census.gov/profile/Bradley_County,_Tennessee?g=050XX00US47011",
        cities: ["Charleston", "Cleveland", "East Cleveland", "Hopewell", "McDonald", "South Cleveland", "Wildewood Lake"]
    },
    {
        name: "Clay",
        government: "https://visitclaycountytn.com/project/government/",
        demographics: "https://data.census.gov/profile/Clay_County,_Tennessee?g=050XX00US47027",
        cities: ["Celina"]
    },
    {
        name: "Cumberland",
        government: "https://cumberlandcountytn.gov/",
        demographics: "https://data.census.gov/profile/Cumberland_County,_Tennessee?g=050XX00US47035",
        cities: ["Bowman", "Crab Orchard", "Crossville", "Fair Glade", "Lake Tansi", "Mayland", "Pleasant Hill"]
    },
    {
        name: "Dekalb",
        government: "https://www.dekalbtennessee.com/",
        demographics: "https://data.census.gov/profile/DeKalb_County,_Tennessee?g=050XX00US47041",
        cities: ["Alexandria", "Dowelltown", "Liberty", "Smithville"]
    },
    {
        name: "Fentress",
        government: "https://fentresscountytn.gov/",
        demographics: "https://data.census.gov/profile/Fentress_County,_Tennessee?g=050XX00US47049",
        cities: ["Allardt", "Clarkrange", "Grimsley", "Jamestown"]
    },
    {
        name: "Grundy",
        government: "https://www.grundycountytn.net/",
        demographics: "https://data.census.gov/profile/Grundy_County,_Tennessee?g=050XX00US47061",
        cities: ["Altamont", "Beersheba Springs", "Coalmont", "Gruetl-Laager", "Monteagle", "Pelham", "Tracy City"]
    },
    {
        name: "Hamilton",
        government: "https://www.hamiltontn.gov/",
        demographics: "https://data.census.gov/profile/Hamilton_County,_Tennessee?g=050XX00US47065",
        cities: ["Apison", "Chattanooga", "Collegedale", "East Ridge", "Fairmount", "Falling Water", "Flat Top Mountain", "Harrison", "Lakesite", "Lookout Mountain", "Middle Valley", "Mowbray Mountain", "Ooltewah", "Red Bank", "Ridgeside", "Sale Creek", "Signal Mountain", "Walden"]
    },
    {
        name: "Jackson",
        government: "https://www.jacksoncotn.com/index.php",
        demographics: "https://data.census.gov/profile/Jackson_County,_Tennessee?g=050XX00US47087",
        cities: ["Dodson Branch", "Gainsboro"]
    },
    {
        name: "Marion",
        government: "https://marioncountytn.net/",
        demographics: "https://data.census.gov/profile/Marion_County,_Tennessee?g=050XX00US47115",
        cities: ["Griffith Creek", "Jasper", "Kimball", "Monteagle", "New Hope", "Orme", "Powells Crossroads", "Sequatche", "South Pittsburg", "Whiteside", "Whitwell"]
    },
    {
        name: "McMinn",
        government: "https://www.mcminncountytn.gov/",
        demographics: "https://data.census.gov/profile/McMinn_County,_Tennessee?g=050XX00US47107",
        cities: ["Athens", "Calhoun", "Englewood", "Etowah", "Niota", "Riceville"]
    },
    {
        name: "Meigs",
        government: "https://www.meigscounty.org/",
        demographics: "https://data.census.gov/profile/Meigs_County,_Tennessee?g=050XX00US47121",
        cities: ["Decatur"]
    },
    {
        name: "Overton",
        government: "https://overtoncountytn.gov/",
        demographics: "https://data.census.gov/profile/Overton_County,_Tennessee?g=050XX00US47133",
        cities: ["Hilham", "Livingston"]
    },
    {
        name: "Pickett",
        government: null,
        demographics: "https://data.census.gov/profile/Pickett_County,_Tennessee?g=050XX00US47137",
        cities: ["Byrdstown"]
    },
    {
        name: "Polk",
        government: "https://www.polkgovernment.com/",
        demographics: "https://data.census.gov/profile/Polk_County,_Tennessee?g=050XX00US47139",
        cities: ["Benton", "Conasauga", "Copperhill", "Delano", "Ducktown", "Farner", "Ocoee"]
    },
    {
        name: "Putnam",
        government: "https://putnamcountytn.gov/",
        demographics: "https://data.census.gov/profile/Putnam_County,_Tennessee?g=050XX00US47141",
        cities: ["Algood", "Baxter", "Cookeville", "Monterey"]
    },
    {
        name: "Rhea",
        government: "https://rheacountytn.org/",
        demographics: "https://data.census.gov/profile/Rhea_County,_Tennessee?g=050XX00US47143",
        cities: ["Dayton", "Graysville", "Spring City"]
    },
    {
        name: "Roane",
        government: "https://roanecountytn.gov/",
        demographics: "https://data.census.gov/profile/Roane_County,_Tennessee?g=050XX00US47145",
        cities: ["Harriman", "Kingson", "Midtown", "Oak Ridge", "Oliver Springs", "Rockwood"]
    },
    {
        name: "Scott",
        government: "https://scottcounty.com/",
        demographics: "https://data.census.gov/profile/Scott_County,_Tennessee?g=050XX00US47151",
        cities: ["Elgin", "Helenwood", "Huntsville", "Oneida", "Robbins", "Winfield"]
    },
    {
        name: "Sequatchie",
        government: "https://sequatchiecountytn.gov/",
        demographics: "https://data.census.gov/profile/Sequatchie_County,_Tennessee?g=050XX00US47153",
        cities: ["Dunlap", "Loan Oak"]
    },
    {
        name: "Van Buren",
        government: "https://vanburencountytn.com/",
        demographics: "https://data.census.gov/profile/Van_Buren_County,_Tennessee?g=050XX00US47175",
        cities: ["Spenser"]
    },
    {
        name: "Warren",
        government: "https://www.warrencountytn.gov/",
        demographics: "https://data.census.gov/profile/Warren_County,_Tennessee?g=050XX00US47177",
        cities: ["Centertown", "McMinnville", "Morrison", "Viola"]
    },
    {
        name: "White",
        government: "https://whitecountytn.gov/visitors/about-white-county",
        demographics: "https://data.census.gov/profile/White_County,_Tennessee?g=050XX00US47185",
        cities: ["Bon Air", "Doyal", "Sparta"]
    }
];

export default MiddleEastTNCounties;
