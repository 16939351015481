import React, { useState } from 'react';
import { FaHandshake } from 'react-icons/fa'; 
import './ServiceProviderApp.css';  
import { Helmet } from 'react-helmet';
import emailjs from '@emailjs/browser';  // Import EmailJS

const ServiceProviderApp = () => {
  const [cities, setCities] = useState('');
  const [services, setServices] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [yearsInBusiness, setYearsInBusiness] = useState('');
  const [licenseNumber, setLicenseNumber] = useState(''); // New field for License Number
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);  // Error handling for form submission

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_ofon8s9',    // Replace with your EmailJS Service ID
      'template_eax7uaj',   // Replace with your EmailJS Template ID
      e.target,             // The form
      'nV96yKTRO0VMybmhK'        // Replace with your EmailJS User ID (public key)
    )
    .then((result) => {
      setSubmitted(true);
      setError(null); // Reset the error state
    }, (error) => {
      setError('Error submitting the application. Please try again.');
    });
  };

  return (
    <div className="service-provider-page">
      <Helmet>
        <title>Service Provider Application - Relocate-TN</title>
        <meta name="description" content="Apply to be a service provider listed on Relocate-TN." />
        <meta name="keywords" content="service provider, relocation, Tennessee, real estate, business" />
        <link rel="canonical" href="https://www.relocate-tn.com/application" />
      </Helmet>

      <section className="hero-provider">
        <div className="overlay-text-provider-left">
          <h2 style={{ fontStyle: 'italic' }}>Opportunity is Knocking!</h2>
          <p>
            Currently, there is no cost for you or your firm to appear on this site. We are in the process of collecting data on web traffic and building a following on this website. We do reserve the right at some future date to begin charging a monthly fee for referrals and exposure on www.Relocate-TN.com. At that time, you will be presented with website metrics and will be able to decline continued participation on the website and/or receiving referrals. We welcome your partnership!
          </p>
        </div>
        <div className="provider-image-right">
          {/* Your Provider.png image will go here */}
        </div>
      </section>

      <section className="provider-form-section">
        {submitted ? (
          <div className="provider-form-thank-you-message">
            <h3>Thank you for your application!</h3>
            <p>We will contact you to discuss adding your information to our website.</p>
          </div>
        ) : (
          <form className="provider-form" onSubmit={handleSubmit}>
            <div className="provider-form-title">
              <FaHandshake className="provider-form-title-icon" />
              <h3>Service Provider Application</h3>
            </div>

            <label>List cities/counties that you currently cover.</label>
            <textarea
              name="citiescovered"  // Added name attribute for EmailJS
              value={cities}
              onChange={(e) => setCities(e.target.value)}
              placeholder="Enter cities/counties"
              className="provider-form-textarea"
              required
            />

            <label>What services do you provide to people relocating?</label>
            <textarea
              name="services"  // Added name attribute for EmailJS
              value={services}
              onChange={(e) => setServices(e.target.value)}
              placeholder="Describe your services"
              className="provider-form-textarea"
              required
            />

            <label>What is your company name?</label>
            <input
              type="text"
              name="companyName"  // Added name attribute for EmailJS
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder="Enter your company name"
              className="provider-form-input"
              required
            />

            <label>How many years have you been in this business?</label>
            <input
              type="number"
              name="yearsInBusiness"  // Added name attribute for EmailJS
              value={yearsInBusiness}
              onChange={(e) => setYearsInBusiness(e.target.value)}
              placeholder="Enter years in business"
              className="provider-form-input"
              min="0"
              required
            />

            <label>License Number (optional).</label>
            <input
              type="text"
              name="licenseNumber"  // Added name attribute for EmailJS
              value={licenseNumber}
              onChange={(e) => setLicenseNumber(e.target.value)}
              placeholder="Enter License Number"
              className="provider-form-input"
            />

            <label>Please enter your first and last name.</label>
            <div className="provider-form-name-fields">
              <input
                type="text"
                name="firstName"  // Added name attribute for EmailJS
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                className="provider-form-input"
                required
              />
              <input
                type="text"
                name="lastName"  // Added name attribute for EmailJS
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                className="provider-form-input"
                required
              />
            </div>

            <label>Please enter your telephone number.</label>
            <input
              type="tel"
              name="phone"  // Added name attribute for EmailJS
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Your Phone Number"
              className="provider-form-input"
              required
            />

            <label>Please enter your email address.</label>
            <input
              type="email"
              name="email"  // Added name attribute for EmailJS
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your Email"
              className="provider-form-input"
              required
            />

            <button type="submit" className="provider-form-button">Submit Application</button>
            {error && <p className="provider-form-error">{error}</p>}
          </form>
        )}
      </section>
    </div>
  );
};

export default ServiceProviderApp;
